<template>
  <div id="app" class="entertainment">
    <header-entertainment-component></header-entertainment-component>
    <router-view class="main-content"></router-view>
  </div>
</template>

<script>
    import HeaderEntertainmentComponent from "../entertainment/components/HeaderComponent";

export default {
  name: 'App',
  components: {HeaderEntertainmentComponent},
}
</script>

<style scoped lang="scss">
  @import "../assets/css/colors";
  @import "../assets/css/fonts";

#app {
  font-family: $font-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-primary;
}
 .entertainment{
   background-color: black;
   min-height: 100%;

 }
  .main-content{
    margin-top: 80px;
    min-height: 92.1vh;
  }



</style>
